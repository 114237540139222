import React from "react";
import "./Home.style.css";
import mariageImage from "../../assets/HomePage/mariage.jpg";
import anniversaireImage from "../../assets/HomePage/anniversaire.jpg";
import baptemeImage from "../../assets/HomePage/bapteme.jpg";

const Home = () => {
  return (
    <div className="home-section">
      <div className="image-container">
        <a href="/portfolio/Anniversaires">
          <div
            className="home-image"
            style={{ backgroundImage: `url(${anniversaireImage})` }}
          ></div>
          <div className="image-overlay"></div> {/* Overlay sombre */}
          <span className="image-label">Anniversaires</span>
          <div className="portfolio-link">Voir le Portfolio</div>
          {/* Nouvelle ligne */}
        </a>
      </div>
      <div className="image-container">
        <a href="/portfolio/Mariages">
          <div
            className="home-image"
            style={{ backgroundImage: `url(${mariageImage})` }}
          ></div>
          <div className="image-overlay"></div> {/* Overlay sombre */}
          <span className="image-label">Mariages</span>
          <div className="portfolio-link">Voir le Portfolio</div>
          {/* Nouvelle ligne */}
        </a>
      </div>
      <div className="image-container">
        <a href="/portfolio/Baptemes">
          <div
            className="home-image"
            style={{ backgroundImage: `url(${baptemeImage})` }}
          ></div>
          <div className="image-overlay"></div> {/* Overlay sombre */}
          <span className="image-label">Baptêmes</span>
          <div className="portfolio-link">Voir le Portfolio</div>
          {/* Nouvelle ligne */}
        </a>
      </div>
    </div>
  );
};

export default Home;
