import React, { useEffect } from "react";
import { useState } from "react";
import "./Portfolio.style.css";
import { useSpring, animated } from "react-spring";
import data from "../../assets/images.json";
import Masonry from "react-masonry-css";
import { useParams } from "react-router-dom";
import FullscreenImage from "../../components/FullScreen/FullScreen";

const Portfolio = () => {
  const params = useParams();

  const [title, setTitle] = useState(params?.folder);
  const [subFolder, setSubFolder] = useState("eglise");
  const [imageArray, setImageArray] = useState([]);

  useEffect(() => {
    setTitle(params?.folder);
  }, [params]);

  useEffect(() => {
    // Fonction pour générer la clé imageArray en fonction de title et subFolder
    const generateImageArrayKey = () => {
      switch (title) {
        case "Mariages":
          switch (subFolder) {
            case "eglise":
              return "mariagesegliseImages";
            case "evjf":
              return "mariagesevjfImages";
            case "mairie":
              return "mariagesmairieImages";
            case "prep_mairie":
              return "mariagesprep_mairieImages";
            case "shooting_couple":
              return "mariagesshooting_coupleImages";
            case "shooting_invite":
              return "mariagesshooting_inviteImages";
            case "vin_honneur":
              return "mariagesvin_honneurImages";
            default:
              break;
          }
          break;
        case "Baptemes":
          return "baptemesImages";
        case "Anniversaires":
          return "anniversairesImages";
        case "Animaux":
          return "animauxImages";
        default:
          break;
      }
    };

    // Mettre à jour imageArray avec la nouvelle clé générée
    setImageArray(data[generateImageArrayKey()]);
  }, [title, subFolder]);

  // Fonction pour gérer le changement de sous-dossier
  const handleSubFolderChange = (newSubFolder) => {
    setSubFolder(newSubFolder);
  };

  const imageSprings = useSpring({
    config: { tension: 170, friction: 14 },
    from: { opacity: 0, transform: "scale(0.8)" },
    to: { opacity: 1, transform: "scale(1)" },
  });

 const [fullscreenImageIndex, setFullscreenImageIndex] = useState(null);

 const handleImageClick = (index) => {
   setFullscreenImageIndex(index);
 };

 const handleCloseFullscreenImage = () => {
   setFullscreenImageIndex(null);
 };

 const handleNextImage = () => {
   if (
     fullscreenImageIndex !== null &&
     fullscreenImageIndex < imageArray.length - 1
   ) {
     setFullscreenImageIndex(fullscreenImageIndex + 1);
   }
 };

 const handlePreviousImage = () => {
   if (fullscreenImageIndex !== null && fullscreenImageIndex > 0) {
     setFullscreenImageIndex(fullscreenImageIndex - 1);
   }
 };


  return (
    <div className="mariages-page">
      <div className="mariages-header">
        <h1>{title}</h1>
        {title === "Mariages" && (
          <ul className="sub-folder-list">
            <li
              className={subFolder === "eglise" ? "active" : ""}
              onClick={() => handleSubFolderChange("eglise")}
            >
              Église
            </li>
            <li
              className={subFolder === "evjf" ? "active" : ""}
              onClick={() => handleSubFolderChange("evjf")}
            >
              EVJF
            </li>
            <li
              className={subFolder === "mairie" ? "active" : ""}
              onClick={() => handleSubFolderChange("mairie")}
            >
              Mairie
            </li>
            <li
              className={subFolder === "prep_mairie" ? "active" : ""}
              onClick={() => handleSubFolderChange("prep_mairie")}
            >
              Préparation Mairiée
            </li>
            <li
              className={subFolder === "shooting_couple" ? "active" : ""}
              onClick={() => handleSubFolderChange("shooting_couple")}
            >
              Shooting Couple
            </li>
            <li
              className={subFolder === "shooting_invite" ? "active" : ""}
              onClick={() => handleSubFolderChange("shooting_invite")}
            >
              Shooting Invité
            </li>
            <li
              className={subFolder === "vin_honneur" ? "active" : ""}
              onClick={() => handleSubFolderChange("vin_honneur")}
            >
              Vin d'Honneur
            </li>
          </ul>
        )}
      </div>
      <Masonry
        breakpointCols={{ default: 4, 1100: 3, 700: 2, 500: 1 }}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {imageArray.map((imagePath, index) => (
          <div
            key={index}
            style={imageSprings}
            // initial={{ y: 0 }}
            // animate={{ y: 1 }}
            // transition={{ duration: 1, ease: "easeInOut", delay: 0.1 }}
          >
            <img
              src={imagePath}
              alt={`Image ${index + 1}`}
              className="image"
              onClick={() => handleImageClick(index)}
            />
          </div>
        ))}
      </Masonry>
      {fullscreenImageIndex !== null && (
        <FullscreenImage
          imageUrl={imageArray[fullscreenImageIndex]}
          onClose={handleCloseFullscreenImage}
          onNext={handleNextImage}
          onPrevious={handlePreviousImage}
          isFirstImage={fullscreenImageIndex === 0}
          isLastImage={fullscreenImageIndex === imageArray.length - 1}
        />
      )}
    </div>
  );
};

export default Portfolio;
