// App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Header from "./components/header/Header";
import Home from "./pages/Home/Home";
import Portfolio from "./pages/Portfolio/Portfolio";
import About from "./pages/About/About";
import Booking from "./pages/Booking/Booking";
import Contact from "./pages/Contact/Contact";
import Prestations from "./pages/Prestations/Prestations";
import Footer from "./components/Footer/Footer";

const App = () => {
  return (
    <Router>
      <div
        style={{
          display: "flex",
          minHeight: "100vh",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: "#fbfbfb",
          alignItems: "center",
        }}
      >
        <Header />
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/portfolio/:folder" element={<Portfolio />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/prestations" element={<Prestations />} />
          <Route path="/" element={<Navigate to="/home" />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
