import React from "react";
import "./About.style.css";
import signature from "../../assets/Logo/signature2.gif";
import pauline1 from "../../assets/About/pauline1.jpg";
import pauline2 from "../../assets/About/pauline2.jpg";
import { motion } from "framer-motion";

const About = () => {
  return (
    <div className="about-page">
      <div className="intro-section">
        <motion.div
          className="intro-text"
          initial={{ x: "-100vw" }}
          animate={{ x: 0 }}
          transition={{ duration: 1, ease: "easeInOut", delay: 0.1 }}
        >
          <p className="titre">
            Capturer l'Éclat des Moments Uniques : Mariage, Anniversaire,
            Baptême et de tous les évènements importants de votre vie
          </p>
          <p className="text" style={{ textAlign: "center" }}>
            <em>
              Je m'appelle <strong>Pauline</strong>, photographe passionnée de
              25 ans.
            </em>
            <br />
            <em>
              Titulaire d'un baccalauréat professionnel en photographie et en
              cours de validation de mon BTS dans le même domaine.
            </em>
            <br />
          </p>
          <div className="services-section">
            <p className="text">
              Aujourd'hui, je vous propose mes services pour immortaliser vos
              moments les plus précieux. Que ce soit lors d'un baptême empreint
              de spiritualité, d'un anniversaire vibrant de joie ou d'un mariage
              célébrant l'amour, je suis là pour capturer chaque instant avec
              authenticité et émotion.
              <br />
              <br />
              Basée dans la région de Dunkerque, Lille, Saint-Omer, Calais, je
              me déplace avec enthousiasme pour vous offrir des souvenirs
              inoubliables en images. Mon approche artistique se nourrit de
              votre histoire et de vos émotions, afin de créer des photographies
              qui reflètent véritablement qui vous êtes et ce qui vous rend
              uniques.
              <br />
              <br />
              En travaillant avec moi, vous bénéficiez d'un service
              professionnel et attentionné, où chaque détail est pris en compte
              pour que vos souvenirs restent intacts pour les générations à
              venir. Faites confiance à mon expertise et laissez-moi capturer
              les moments magiques de votre vie.
            </p>
          </div>
        </motion.div>
        <motion.div
          className="profile-image"
          initial={{ x: "100vw" }}
          animate={{ x: 0 }}
          transition={{ duration: 1, ease: "easeInOut", delay: 0.1 }}
        >
          <img src={pauline1} alt="Pauline Limousin" />
        </motion.div>
      </div>

      <div className="trait" />
      <div className="intro-section">
        <div className="profile-image">
          <img src={pauline2} alt="Pauline Limousin" />
        </div>
        <div className="intro-text">
          <p className="titre">Une histoire, un parcours...</p>
          <div className="services-section">
            <p className="text">
              Originaire du Nord de la France, la photographie a toujours été au
              cœur de ma vie. Depuis mes premiers pas dans ce domaine, j'ai été
              fascinée par la capacité de l'appareil photo à capturer l'essence
              même des moments et des émotions.
              <br />
              <br />
              Mon parcours dans le monde de la photographie a commencé avec
              l'obtention d'un baccalauréat professionnel en photographie, une
              étape cruciale qui a posé les fondations de mon expertise.
              Actuellement en cours de validation de mon BTS dans le même
              domaine, je continue de me former et de perfectionner mes
              compétences pour rester à la pointe de mon art.
              <br />
              <br />
              Fort de cinq années d'expérience en tant que photographe de mode,
              j'ai eu le privilège de collaborer avec des personnalités telles
              que Camille Cerf, Miss France 2015, ainsi que son compagnon Théo.
              Ces collaborations m'ont permis de développer un sens aigu de
              l'esthétique et de la technique, tout en explorant la beauté sous
              toutes ses formes.
              <br />
              <br />
              Ma passion pour la photographie va au-delà de la simple prise de
              vue ; c'est un véritable mode d'expression pour moi. J'aime
              capturer la vérité et la beauté qui résident dans chaque instant
              de la vie, et c'est cet amour pour les gens et leur histoire qui
              me motive chaque jour.
              <br />
              <br />
              En tant qu'aventurière autonome, je suis toujours à l'écoute de
              vos besoins et de vos désirs photographiques. Parcourez mes
              galeries pour découvrir mon travail et n'hésitez pas à me
              contacter pour discuter de vos projets. Je suis impatiente de
              contribuer à rendre vos moments spéciaux éternels à travers mon
              objectif.
            </p>

            <img
              style={{ width: "200px", alignSelf: "center", marginTop: "20px" }}
              src={signature}
              alt="Pauline Limousin"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
