import React from "react";

function Contact() {
  return (
    <div>
      <h2>Informations de contact</h2>
      <div>
        <p>
          <strong>Nom:</strong> Limousin
        </p>
        <p>
          <strong>Prénom:</strong> Pauline
        </p>
        <p>
          <strong>Numéro de téléphone:</strong> 06 35 21 23 02
        </p>
        <p>
          <strong>E-mail:</strong> paulinelimous1@gmail.com
        </p>
      </div>
    </div>
  );
}

export default Contact;
