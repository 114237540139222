import React from "react";
import "./FullScreen.css";

const FullscreenImage = ({
  imageUrl,
  onClose,
  onNext,
  onPrevious,
  isFirstImage,
  isLastImage,
}) => {
  return (
    <div className="fullscreen-overlay">
      <button
        className={
          isFirstImage ? "arrow-button disabled" : "arrow-button left-arrow"
        }
        onClick={onPrevious}
      >
        {"<"}
      </button>
      <span className="close-button" onClick={onClose}>
        &times;
      </span>
      <img className="fullscreen-image" src={imageUrl} alt="Fullscreen Image" />

      <button
        className={
          isLastImage ? "arrow-button disabled" : "arrow-button right-arrow"
        }
        onClick={onNext}
      >
        {">"}
      </button>
    </div>
  );
};

export default FullscreenImage;
