import React from "react";
import { motion } from "framer-motion";
import "./Prestations.style.css";

const Prestations = () => {
  return (
    <div className="container">
      <motion.h1
        initial={{ y: "-50vw" }}
        animate={{ y: 0 }}
        transition={{ duration: 1, ease: "easeInOut", delay: 0.1 }}
      >
        Comment ça se passe ?
      </motion.h1>
      <motion.p
        className="text"
        initial={{ y: "50vw" }}
        animate={{ y: 0 }}
        transition={{ duration: 1, ease: "easeInOut", delay: 0.1 }}
      >
        En tant que photographe spécialisé dans l'événementiel, je m'engage à
        offrir un service professionnel et personnalisé pour capturer les
        moments précieux de votre mariage ou de tout autre événement spécial.
        Voici ma méthode de travail :<h2>1. Consultation initiale :</h2>
        Nous commençons par discuter de vos besoins et de vos attentes pour
        votre événement. Je recueille toutes les informations pertinentes telles
        que l'adresse, les lieux, la date et l'horaire de l'événement.
        <h2>2. Devis et réservation :</h2>
        Sur la base des informations fournies, je vous propose un devis
        transparent et compétitif. Une fois que nous avons convenu du prix, nous
        bloquons la date pour garantir ma disponibilité.
        <h2>3. Confirmation des détails :</h2>
        Ensuite, j'ai besoin du nom des mariés ou des organisateurs de
        l'événement, de leur numéro de téléphone ainsi que des horaires et de
        l'adresse exacte de l'événement pour finaliser les préparatifs.
        <h2>4. Suivi à deux semaines de l'événement :</h2>
        Deux semaines avant la date prévue, je vous recontacte pour confirmer
        que les détails tels que le lieu et les horaires n'ont pas changé.
        <h2>5. Jour de l'événement :</h2>
        Le jour J, je me présente sur le lieu de l'événement prêt à capturer
        chaque instant avec professionnalisme, joie et bonne humeur.
        <h2>6. Post-production :</h2>
        Une fois les photos prises, je passe deux à trois semaines à les
        retoucher avec soin. Chaque photo est traitée individuellement pour
        garantir la meilleure qualité possible. Mon style de retouche inclut une
        palette de couleurs vibrantes ainsi qu'un noir et blanc riche et
        contrasté.
        <h2>7. Remise des photos :</h2>
        Si nous avons convenu de remettre les photos sur clé USB, je me déplace
        jusqu'à chez vous pour visionner ensemble les photos et vous remettre la
        clé USB. Le paiement se fait en deux étapes : la moitié le jour de
        l'événement et le solde à la remise des photos.
        <h2>8. Options de livraison alternatives :</h2>
        Si nous avons opté pour une livraison via WeTransfer (transfert
        internet), vous avez le choix de régler la totalité le jour de
        l'événement ou de verser la moitié le jour même et le reste via
        virement. Mon objectif est de vous offrir une expérience sans souci et
        des souvenirs inoubliables de votre événement. N'hésitez pas à me
        contacter pour discuter de vos besoins spécifiques et pour réserver
        votre date dès aujourd'hui.
      </motion.p>
    </div>
  );
};

export default Prestations;
