import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.style.css";
import logo from "../../assets/Logo/logo.png";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [subSelected, setSubSelected] = useState("");

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleLinkClick = (title) => {
    setSelected("portfolio");
    setSubSelected(title);
    closeDropdown();
  };
  console.log(selected);

  return (
    <div className="header">
      <div className="logo">
        <a href="/">
          <img src={logo} alt="Logo" className="logoImg" />
        </a>
      </div>
      <nav className="navbar">
        <ul>
          <li>
            <Link
              to="/home"
              className={selected === "home" ? "selected" : ""}
              onClick={() => setSelected("home")}
            >
              ACCUEIL
            </Link>
          </li>
          <li className="portfolio">
            <div
              onClick={handleDropdownToggle}
              className={selected === "portfolio" ? "selected" : ""}
            >
              PORTFOLIO <span className="arrow-down"></span>
            </div>
            {isOpen && (
              <ul className="dropdown">
                <li onClick={() => handleLinkClick("Mariages")}>
                  <Link
                    to="/portfolio/Mariages"
                    className={
                      selected === "portfolio" && subSelected === "Mariages"
                        ? "selected"
                        : ""
                    }
                  >
                    Mariages
                  </Link>
                </li>
                <li onClick={() => handleLinkClick("Anniversaires")}>
                  <Link
                    to="/portfolio/Anniversaires"
                    className={
                      selected === "portfolio" &&
                      subSelected === "Anniversaires"
                        ? "selected"
                        : ""
                    }
                  >
                    Anniversaires
                  </Link>
                </li>
                <li onClick={() => handleLinkClick("Baptemes")}>
                  <Link
                    to="/portfolio/Baptemes"
                    className={
                      selected === "portfolio" && subSelected === "Baptemes"
                        ? "selected"
                        : ""
                    }
                  >
                    Baptêmes
                  </Link>
                </li>
                <li onClick={() => handleLinkClick("Animaux")}>
                  <Link
                    to="/portfolio/Animaux"
                    className={
                      selected === "portfolio" && subSelected === "Animaux"
                        ? "selected"
                        : ""
                    }
                  >
                    Animaux
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <Link
              to="/about"
              className={selected === "about" ? "selected" : ""}
              onClick={() => setSelected("about")}
            >
              A PROPOS
            </Link>
          </li>
          <li>
            <Link
              to="/prestations"
              className={selected === "prestations" ? "selected" : ""}
              onClick={() => setSelected("prestations")}
            >
              PRESTATIONS
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className={selected === "contact" ? "selected" : ""}
              onClick={() => setSelected("contact")}
            >
              CONTACTS
            </Link>
          </li>
        </ul>
      </nav>
      <div className="trait" />
    </div>
  );
};

export default Header;
