import React from "react";
import "./Footer.style.css"; // Assurez-vous d'importer votre fichier de style CSS

const Footer = () => {
  return (
    <footer className="footer">
      <div className="trait" />
      <div className="social-icons">
        <a
          href="https://www.instagram.com/paulinelimousin/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram"></i>
        </a>
        <a
          href="https://www.facebook.com/paulinelimous1/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-facebook"></i>
        </a>
        <a
          href="https://www.linkedin.com/in/limousin-pauline-1237632a5/?originalSubdomain=fr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-linkedin"></i>
        </a>
      </div>
      <p className="copyright">Copyright © 2024 · All Rights Reserved</p>
    </footer>
  );
};

export default Footer;
